import { camelCase, snakeCase } from 'lodash';
import imageCompression from 'browser-image-compression';
import dayjs from 'dayjs';
import { isAfter, isSameDay } from 'date-fns';

export const toCamelCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => toCamelCase(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: toCamelCase(obj[key])
            }),
            {}
        );
    }
    return obj;
};

export const isDatePassed = (targetDate) => {
    return dayjs().format('YYMMDD') > dayjs(targetDate).format('YYMMDD');
};

export const uniqueArray = (array) =>
    array.filter((item, index, self) => index === self.findIndex((i) => i.id === item.id));

export const toSnakeCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => toSnakeCase(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [snakeCase(key)]: toSnakeCase(obj[key])
            }),
            {}
        );
    }
    return obj;
};

export const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};

export const columns = (...array) => {
    return array.join();
};

export const sleep = (delay) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), delay);
    });
};

export const getArticleTypeDisplayName = (a) =>
    a ? `${a.name}/${a.color}-${a.size} (${a.sizeUnit})` : ``;

export const compressImage = async (file, options) => {
    const compressed = await imageCompression(file, options);
    return compressed;
};

export const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(price);
};

export const generateRandomColors = (numColors) => {
    let colors = [];

    for (let i = 0; i < numColors; i++) {
        let color =
            '#' +
            Math.floor(Math.random() * 16777215)
                .toString(16)
                .padStart(6, '0');
        colors.push(color);
    }

    return colors;
};

export const camelCaseToSnakeCase = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    const snakeCaseObj = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const snakeKey = key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
            snakeCaseObj[snakeKey] = camelCaseToSnakeCase(obj[key]);
        }
    }

    return snakeCaseObj;
};

export const isGreaterOrEqualToTodaysDate = (dateToCheck) => {
    const currentDate = new Date();

    const isGreaterOrEqual =
        isAfter(new Date(dateToCheck), currentDate) ||
        isSameDay(new Date(dateToCheck), currentDate);

    return isGreaterOrEqual;
};
