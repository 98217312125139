import AuthService from './AuthService';
import StaffService from './StaffService';
import TeacherService from './TeacherService';
import StudentService from './StudentService';
import CourseService from './CourseService';
import DiscountService from './DiscountService';
import BatchService from './BatchService';
import ClassService from './ClassService';
import BranchService from './BranchService';
import EnrollmentRequestService from './EnrollmentRequestService';
import DashboardService from './DashboardService';
import PaymentService from './PaymentService';
import SiteContentService from './SiteContentService';

export const authService = new AuthService();
export const staffService = new StaffService();
export const teacherService = new TeacherService();
export const studentService = new StudentService();
export const courseService = new CourseService();
export const discountService = new DiscountService();
export const batchService = new BatchService();
export const classService = new ClassService();
export const branchService = new BranchService();
export const enrollmentRequestService = new EnrollmentRequestService();
export const dashboardService = new DashboardService();
export const paymentService = new PaymentService();
export const siteContentService = new SiteContentService();
