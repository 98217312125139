import { createContext } from 'react';
import useCurrentUser from '../hooks/useCurrentUser';
import { authService } from '../services';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const { currentUser, loading } = useCurrentUser();

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                loading,
                signIn: authService.signIn,
                signOut: authService.signOut
            }}>
            {children}
        </AuthContext.Provider>
    );
};
