import { authService } from '.';
import { supabase, supabaseAuth } from '../adapters/supabase';
import { Roles, Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, isDatePassed, toCamelCase } from '../utils/utils';

class AuthService {
    async signIn({ email, password }) {
        return withSafety(async () => {
            const { error } = await supabaseAuth.signIn({ email, password });
            if (error) throw error;
            return AppResult.withData(this.getCurrentUser());
        });
    }
    async updateLastLogin({ userId }) {
        return withSafety(async () => {
            await supabase
                .from(Table.PROFILES)
                .update({ last_login: new Date() })
                .eq('id', userId)
                .throwOnError();

            return AppResult.withData(null);
        });
    }

    async signOut() {
        return withSafety(async () => {
            const { error } = await supabaseAuth.signOut();
            if (error) throw error;
        });
    }

    getCurrentUser() {
        if (supabaseAuth.user) return supabaseAuth.user();
    }

    async getUserDetailsByEmail({ email }) {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.PROFILES)
                .select('*')
                .eq('email', email);

            if (error) throw error;
            return AppResult.withData(toCamelCase(data));
        });
    }

    async fetchProfile() {
        return withSafety(async () => {
            const { id } = this.getCurrentUser();

            const { data, error } = await supabase
                .from(Table.PROFILES)
                .select(columns('*'))
                .eq('is_active', true)
                .eq('id', id)
                .in('role', [
                    Roles.SUPERADMIN,
                    Roles.ADMIN,
                    Roles.COUNSELLOR,
                    Roles.OPERATION_MANAGER,
                    Roles.SALES_MARKETING,
                    Roles.TEACHER
                ])
                // .filter('end_date', 'gt', new Date().toISOString())
                // .or('end_date.is.null')
                .single();

            if (error || data == null || isDatePassed(data?.end_date || new Date())) {
                authService.signOut();
                console.log('hi');
                throw new Error('User not found');
            }

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default AuthService;
