import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class DiscountService {
    async createDiscount(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.DISCOUNTS)
                .insert({
                    code: payload.code,
                    discount_amount: payload.discountAmount,
                    discount_percentage: payload.discountPercentage,
                    max_use: payload.maxUse,
                    course_id: payload.courseId,
                    expiry_date: payload.expiryDate,
                    created_by: authService.getCurrentUser().id,
                    updated_by: authService.getCurrentUser().id
                })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getDiscounts() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.DISCOUNTS)
                .select(
                    columns(
                        `*`,
                        'course:course_id(name)',
                        `created_by(full_name)`,
                        `updated_by(full_name)`
                    )
                )
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateDiscount(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.DISCOUNTS)
                .update({
                    expiry_date: payload.expiryDate,
                    is_disabled: payload.isDisabled
                })
                .eq('id', payload?.id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default DiscountService;
