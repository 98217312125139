import axios from 'axios';
import { authService } from '.';
import { supabase, supabaseAuth } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class StudentService {
    async createUser(details) {
        return withSafety(async () => {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users`, details, {
                headers: {
                    authorization: supabaseAuth?.session()?.access_token
                }
            });
            return AppResult.withData(res);
        });
    }

    async getMembers() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PROFILES)
                .select(
                    columns(
                        `*`,
                        `created_by(full_name)`,
                        `updated_by(full_name)`,
                        'branch:branch_id(name)'
                    )
                )
                .in('role', ['student'])
                .neq('id', authService.getCurrentUser().id)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getStudentProfile(id) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PROFILES)
                .select(columns(`full_name`, 'email', 'phone', 'is_active', 'avatar_url'))
                .eq('role', 'student')
                .eq('id', id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data[0] || {}));
        });
    }

    async getStudentCourses(studentId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.STUDENTS_COURSES)
                .select(
                    columns(
                        `*`,
                        `course:course_id(${columns('name', 'type')})`,
                        `enrolled_by(${columns('full_name', 'role')})`
                    )
                )
                .eq('student_id', studentId)
                .throwOnError();

            return AppResult.withData(toCamelCase(data || {}));
        });
    }

    async enrollCourse(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.STUDENTS_COURSES)
                .insert({
                    course_id: payload.courseId,
                    student_id: payload?.studentId,
                    enrolled_by: authService.getCurrentUser().id,
                    enrolled_date: new Date()
                })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getCertificate(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.STUDENTS_COURSES)
                .select('certificate_url')
                .eq('course_id', payload.courseId)
                .eq('student_id', payload?.studentId)
                .single()
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
    async uploadCertificate(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.STUDENTS_COURSES)
                .update({
                    certificate_url: payload?.certificateUrl
                })
                .eq('course_id', payload.courseId)
                .eq('student_id', payload?.studentId)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
    async deleteCertificate(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.STUDENTS_COURSES)
                .update({
                    certificate_url: null
                })
                .eq('course_id', payload.courseId)
                .eq('student_id', payload?.studentId)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getStudentBatches(id) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES_STUDENTS)
                .select(
                    columns(
                        `*`,
                        `added_by(full_name)`,
                        `batch_id(*, course_id(name))`,
                        `removed_at`,
                        `removed_by(full_name)`
                    )
                )
                .eq('student_id', id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data || {}));
        });
    }

    async updateUser(details) {
        return withSafety(async () => {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/api/users`, details, {
                headers: {
                    authorization: supabaseAuth?.session()?.access_token
                }
            });

            return AppResult.withData();
        });
    }

    async resetPassword({ userId, password }) {
        return withSafety(async () => {
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/users/reset-password`,
                { userId, password },
                {
                    headers: {
                        authorization: supabaseAuth?.session()?.access_token
                    }
                }
            );

            return AppResult.withData();
        });
    }

    async resetEmail({ userId, email }) {
        return withSafety(async () => {
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/users/reset-email`,
                { userId, email },
                {
                    headers: {
                        authorization: supabaseAuth?.session()?.access_token
                    }
                }
            );

            return AppResult.withData();
        });
    }

    async removeEnrolledCourse(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.STUDENTS_COURSES)
                .delete()
                .eq('course_id', payload?.courseId)
                .eq('student_id', payload?.studentId);

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default StudentService;
