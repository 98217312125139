import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class BranchService {
    async createBranch(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BRANCHES)
                .insert({
                    name: payload.name,
                    country: payload.country,
                    state: payload.state,
                    city: payload.city,
                    zip_code: payload.zipCode,
                    created_by: authService.getCurrentUser().id,
                    updated_by: authService.getCurrentUser().id
                })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getBranches() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BRANCHES)
                .select(columns(`*`, `created_by(full_name)`, `updated_by(full_name)`))
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getBranchesDropdownData() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BRANCHES)
                .select(columns(`*`, `created_by(full_name)`, `updated_by(full_name)`))
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateBranch(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BRANCHES)
                .update({
                    name: payload.name,
                    country: payload.country,
                    state: payload.state,
                    city: payload.city,
                    zip_code: payload.zipCode,
                    is_active: payload.isActive
                })
                .eq('id', payload?.id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default BranchService;
