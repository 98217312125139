import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase, uniqueArray } from '../utils/utils';

class ClassService {
    async createClass(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.CLASSES)
                .insert({
                    topic: payload?.topic,
                    teacher_id: payload?.teacherId,
                    meeting_link: payload?.meetingLink,
                    starts_at: payload?.startsAt,
                    ends_at: payload?.endsAt,
                    notes: payload?.notes,
                    branch_id: payload?.branchId,
                    created_by: authService.getCurrentUser().id,
                    updated_by: authService.getCurrentUser().id
                })
                .throwOnError();

            await supabase
                .from(Table.CLASSES_BATCHES)
                .insert(
                    payload.batches.map((item) => ({
                        class_id: data[0].id,
                        batch_id: item.id
                    }))
                )
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getClasses() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.CLASSES)
                .select(
                    columns(
                        `*`,
                        `teacher_id(full_name, id)`,
                        `created_by(full_name)`,
                        `updated_by(full_name)`,
                        'classes_students(count)',
                        `classes_batches(batch_id(name, id))`,
                        'branch:branch_id(name)'
                    )
                )
                // .eq('classes_students.removed', false)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getStudentList(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES_STUDENTS)
                .select(
                    'student_id!inner(id, full_name, email, avatar_url, batches_students!batches_students_student_id_fkey(batch_id(id, name)))'
                )
                .or('full_name.ilike.%' + payload.name + '%', { foreignTable: 'student_id' })
                .or('email.ilike.%' + payload.email + '%', { foreignTable: 'student_id' })
                .in('batch_id', payload?.batchIds)
                .eq('removed', false);

            const finalData = data?.map((item) => ({
                batches: item?.student_id?.batches_students?.map((el) => el?.batch_id),
                email: item?.student_id?.email,
                full_name: item?.student_id?.full_name,
                id: item?.student_id?.id
            }));

            return AppResult.withData(uniqueArray(toCamelCase(finalData || [])));
        });
    }

    async getClassStudentList(classId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.CLASSES_STUDENTS)
                .select(
                    columns(
                        `*`,
                        'student:student_id(full_name, email, id)',
                        'added_by(full_name, role, id)'
                    )
                )
                .eq('class_id', classId)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async addStudentsToClass(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.CLASSES_STUDENTS)
                .upsert(payload)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async deleteStudentsToClass(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.CLASSES_STUDENTS)
                .delete()
                .eq('class_id', payload.classId)
                .eq('student_id', payload.studentId)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateAttendance(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.CLASSES_STUDENTS)
                .upsert(payload)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
    async updateClass(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.CLASSES)
                .update({
                    topic: payload?.topic,
                    teacher_id: payload?.teacherId,
                    old_teacher_id: payload?.oldTeacherId,
                    meeting_link: payload?.meetingLink,
                    starts_at: payload?.startsAt,
                    ends_at: payload?.endsAt,
                    notes: payload?.notes,
                    recording_link: payload?.recordingLink,
                    branch_id: payload?.branchId,
                    has_teacher_attended: payload?.hasTeacherAttended,
                    created_by: authService.getCurrentUser().id,
                    updated_by: authService.getCurrentUser().id
                })
                .eq('id', payload?.id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default ClassService;
