import React from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@emotion/react';
import './index.css';

import { appConfig } from './utils/config';
import theme from './utils/theme';
import AppRouter from './router/AppRouter';
import { AuthProvider } from './contexts/AuthContext';

if (appConfig.isProduction) {
    console.log('In production');
    console.log = function () {};
    console.error = function () {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <AuthProvider>
                <AppRouter />
            </AuthProvider>
        </SnackbarProvider>
    </ThemeProvider>
);
