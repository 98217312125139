import { Redirect, Route } from 'react-router-dom';

const AppRoute = ({
    component: Component,
    isProtected,
    routes,
    path,
    exact,
    isAuthenticated,
    ...restProps
}) => {
    return (
        <Route
            {...restProps}
            path={path}
            exact={exact}
            render={(props) =>
                !isProtected || isAuthenticated ? (
                    <Component
                        {...props}
                        internalRoutes={routes}
                        isAuthenticated={isAuthenticated}
                    />
                ) : (
                    <Redirect to={{ pathname: '/login' }} />
                )
            }
        />
    );
};

export default AppRoute;
