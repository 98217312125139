import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase, uniqueArray } from '../utils/utils';

class BatchService {
    async createBatch(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES)
                .insert({
                    name: payload?.name,
                    start_date: payload?.startDate,
                    end_date: payload?.endDate,
                    course_id: payload?.courseId,
                    metadata: payload?.metadata,
                    branch_id: payload?.branchId,
                    created_by: authService.getCurrentUser().id,
                    updated_by: authService.getCurrentUser().id
                })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getBatches() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES)
                .select(
                    columns(
                        `*`,
                        'course:course_id(name)',
                        `created_by(full_name)`,
                        `updated_by(full_name)`,
                        'batches_students(count)',
                        'branch:branch_id(name)'
                    )
                )
                .eq('batches_students.removed', false)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getBatchesDropdownData() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES)
                .select(columns(`id`, `name`, 'branch_id'))
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getStudentList(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.STUDENTS_COURSES)
                .select(
                    'student_id!inner(id, full_name, email, avatar_url, batches_students!batches_students_student_id_fkey(batch_id(id, name)))'
                )
                .or('full_name.ilike.%' + payload.name + '%', { foreignTable: 'student_id' })
                .or('email.ilike.%' + payload.email + '%', { foreignTable: 'student_id' })
                .eq('course_id', payload.courseId)
                .eq('can_start', true);

            const finalData = data?.map((item) => ({
                batches: item?.student_id?.batches_students?.map((el) => el?.batch_id),
                email: item?.student_id?.email,
                full_name: item?.student_id?.full_name,
                id: item?.student_id?.id
            }));

            return AppResult.withData(uniqueArray(toCamelCase(finalData || [])));
        });
    }

    async getBatchStudentList(batchId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES_STUDENTS)
                .select(
                    columns(
                        `*`,
                        'student:student_id(full_name, email, id)',
                        'added_by(full_name, role)'
                    )
                )
                .eq('batch_id', batchId)
                .is('removed_by', null)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async addStudentsToBatch(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES_STUDENTS)
                .upsert(payload)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async deleteStudentsToBatch(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES_STUDENTS)
                .update({
                    removed_at: new Date(),
                    removed_by: authService.getCurrentUser().id,
                    removed: true
                })
                .eq('batch_id', payload.batchId)
                .eq('student_id', payload.studentId)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateBatch(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES)
                .update({
                    name: payload?.name,
                    start_date: payload?.startDate,
                    end_date: payload?.endDate,
                    metadata: payload?.metadata,
                    branch_id: payload?.branchId
                })
                .eq('id', payload?.id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default BatchService;
