import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase } from '../utils/utils';

class PaymentService {
    async getPurchases({ coursesIds }) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PURCHASES)
                .select('*, payment_dues(*), courses(*)')
                .in('course_id', [...coursesIds]);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async uploadEmiDoc(emiId, file) {
        return withSafety(async () => {
            const path = `${emiId}.${file.name.split('.').pop()}`;

            const { data } = await supabase.storage.from('emi-docs').upload(path, file, {
                cacheControl: '5',
                upsert: true
            });

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateEmiRecord(newDoc) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PAYMENT_DUES)
                .update({ ...newDoc, paid_at: new Date() })
                .eq('id', newDoc.id);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getCouponCodeDetails({ couponCode, courseId }) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.DISCOUNTS)
                .select('*')
                .eq('code', couponCode)
                .eq('course_id', courseId)
                .single();
            console.log(data);

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default PaymentService;
