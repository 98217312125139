export const Roles = {
    COUNSELLOR: 'counsellor',
    ADMIN: 'admin',
    TEACHER: 'teacher',
    STUDENT: 'student',
    SUPERADMIN: 'superadmin',
    SALES_MARKETING: 'sales_marketing',
    OPERATION_MANAGER: 'operation_manager'
};

export const Permissions = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    READ: 'read'
};

export const Features = {
    STAFF: 'staff',
    TEACHER: 'teacher',
    STUDENT: 'student',
    PAYMENT: 'payment',
    COURSES: 'courses',
    DISCOUNT: 'discount',
    BATCH: 'batch',
    CLASSES: 'classes',
    BRANCHES: 'branches',
    ENROLLMENT: 'enrollment',
    DASHBOARD: 'dashboard',
    SITE_CONTENT: 'siteContent'
};

export const CourseType = {
    SHORT: 'short',
    LONG: 'long'
};

export const Employment = {
    FULL_TIME: 'full-time',
    CONTRACT: 'contract'
};

export const Table = {
    PROFILES: 'profiles',
    COURSES: 'courses',
    DISCOUNTS: 'discounts',
    BATCHES: 'batches',
    CLASSES: 'classes',
    STUDENTS_COURSES: 'students_courses',
    BATCHES_STUDENTS: 'batches_students',
    CLASSES_STUDENTS: 'classes_students',
    CLASSES_BATCHES: 'classes_batches',
    BRANCHES: 'branches',
    ENROLLMENT_REQUESTS: 'enrollment_requests',
    PURCHASES: 'purchases',
    PAYMENT_DUES: 'payment_dues',
    SITE_CONTENT: 'site_content'
};

export const DEFAULT_LIST_PAGE_SIZE = 10;
