import { createTheme } from '@mui/material';

const theme = createTheme({
    typography: {
        fontFamily: 'Inter, sans-serif'
    },
    palette: {
        primary: {
            main: '#8042A8'
        },
        secondary: {
            main: '#d64358'
        }
    },
    shape: {
        borderRadius: 16
    }
});

export default theme;
