import { useSnackbar } from 'notistack';
import { useState, useEffect, useCallback } from 'react';
import { supabaseAuth } from '../adapters/supabase';
import { authService } from '../services';

/**
 * Custom hook for fetching and managing the current user.
 * @returns {Object} The current user and the loading state.
 */
const useCurrentUser = () => {
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    /**
     * Fetch the user profile and handle any errors.
     * @returns {Promise<boolean>} A promise that resolves to true if the profile was fetched successfully, false otherwise.
     */
    const fetchProfile = useCallback(async () => {
        const { data, error } = await authService.fetchProfile();
        if (error || data == null) {
            enqueueSnackbar(error || 'Something went wrong', { variant: 'error' });
            return false;
        }

        setCurrentUser(data);
        return true;
    }, [enqueueSnackbar]);

    useEffect(() => {
        const fetch = async () => {
            const success = await fetchProfile();
            if (success) setLoading(false);
        };

        // Fetch the user profile if the user is already authenticated
        if (authService.getCurrentUser() != null) {
            fetch();
        } else {
            setLoading(false);
        }

        // Listen for changes in the authentication state
        const { data } = supabaseAuth.onAuthStateChange(async (_, session) => {
            if (session?.user != null) {
                await fetchProfile();
            } else {
                setCurrentUser(null);
            }
        });

        // Unsubscribe from the auth state change event when the component is unmounted
        return data.unsubscribe;
    }, [enqueueSnackbar, fetchProfile]);

    return { currentUser, loading };
};

export default useCurrentUser;
