import axios from 'axios';
import { authService } from '.';
import { supabase, supabaseAuth } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class TeacherService {
    async createUser(details) {
        return withSafety(async () => {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users`, details, {
                headers: {
                    authorization: supabaseAuth?.session()?.access_token
                }
            });

            return AppResult.withData();
        });
    }

    async getMembers() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PROFILES)
                .select(
                    columns(
                        `*`,
                        `created_by(full_name)`,
                        `updated_by(full_name)`,
                        'branch:branch_id(name)'
                    )
                )
                .in('role', ['teacher'])
                .neq('id', authService.getCurrentUser().id)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateUser(details) {
        return withSafety(async () => {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/api/users`, details, {
                headers: {
                    authorization: supabaseAuth?.session()?.access_token
                }
            });

            return AppResult.withData();
        });
    }

    async resetPassword({ userId, password }) {
        return withSafety(async () => {
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/users/reset-password`,
                { userId, password },
                {
                    headers: {
                        authorization: supabaseAuth?.session()?.access_token
                    }
                }
            );

            return AppResult.withData();
        });
    }

    async resetEmail({ userId, email }) {
        return withSafety(async () => {
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/users/reset-email`,
                { userId, email },
                {
                    headers: {
                        authorization: supabaseAuth?.session()?.access_token
                    }
                }
            );

            return AppResult.withData();
        });
    }
}

export default TeacherService;
