import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase } from '../utils/utils';

class SiteContentService {
    async getData() {
        return withSafety(async () => {
            const { data } = await supabase.from(Table.SITE_CONTENT).select('*');

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateData(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.SITE_CONTENT)
                .update({
                    content: payload?.content
                })
                .eq('page', payload?.page)
                .throwOnError();
            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default SiteContentService;
