import { lazy } from 'react';
import { Features } from '../utils/common';

export const routes = [
    {
        name: 'login',
        path: '/login',
        component: lazy(() => import('../pages/Login')),
        isProtected: false,
        exact: true
    },
    {
        name: 'app',
        path: '/app',
        component: lazy(() => import('../Layout')),
        isProtected: true,
        routes: [
            {
                page: Features.DASHBOARD,
                name: 'dashboard',
                path: '/app/dashboard',
                component: lazy(() => import('../pages/Dashboard')),
                isProtected: true
            },
            {
                page: Features.STAFF,
                name: 'staff',
                path: '/app/staff',
                component: lazy(() => import('../pages/Staff')),
                isProtected: true
            },
            {
                page: Features.TEACHER,
                name: 'teachers',
                path: '/app/teachers',
                component: lazy(() => import('../pages/Teachers')),
                isProtected: true
            },
            {
                page: Features.TEACHER,
                name: 'teachersProfile',
                path: '/app/teachers/:id',
                component: lazy(() => import('../pages/TeachersProfile')),
                isProtected: true
            },
            {
                page: Features.STUDENT,
                name: 'students',
                path: '/app/students',
                component: lazy(() => import('../pages/Students')),
                isProtected: true
            },
            {
                page: Features.STUDENT,
                name: 'studentsProfile',
                path: '/app/students/:id',
                component: lazy(() => import('../pages/StudentProfile')),
                isProtected: true
            },
            {
                page: Features.STUDENT,
                name: 'studentsProfile',
                path: '/app/students/:id/:courseId',
                component: lazy(() => import('../pages/StudentCourse')),
                isProtected: true
            },
            {
                page: Features.DISCOUNT,
                name: 'discounts',
                path: '/app/discounts',
                component: lazy(() => import('../pages/Discounts')),
                isProtected: true
            },
            {
                page: Features.COURSES,
                name: 'courses',
                path: '/app/courses',
                component: lazy(() => import('../pages/Courses')),
                isProtected: true
            },
            {
                page: Features.BATCH,
                name: 'batch',
                path: '/app/batch',
                component: lazy(() => import('../pages/Batch')),
                isProtected: true
            },
            {
                page: Features.CLASSES,
                name: 'classes',
                path: '/app/classes',
                component: lazy(() => import('../pages/Classes')),
                isProtected: true
            },
            {
                page: Features.BRANCHES,
                name: 'branches',
                path: '/app/branches',
                component: lazy(() => import('../pages/Branches')),
                isProtected: true
            },
            {
                page: Features.ENROLLMENT,
                name: 'enrollmentRequests',
                path: '/app/enrollment-requests',
                component: lazy(() => import('../pages/EnrollmentRequests')),
                isProtected: true
            },
            {
                page: Features.SITE_CONTENT,
                name: 'siteContent',
                path: '/app/manage-website',
                component: lazy(() => import('../pages/ManageWebsite')),
                isProtected: true
            }
        ]
    }
];
