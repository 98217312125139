import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';
import { authService } from '.';

class EnrollmentRequestService {
    async getRequests() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.ENROLLMENT_REQUESTS)
                .select(
                    columns(
                        `*`,
                        `student:student_id(${columns('id', 'full_name', 'email', 'phone')})`,
                        `course:course_id(name)`,
                        `resolved_by(full_name)`
                    )
                )
                .order('requested_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateRequest(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.ENROLLMENT_REQUESTS)
                .update({
                    feedback: payload.feedback,
                    is_resolved: payload.isResolved,
                    resolved_by: authService.getCurrentUser().id
                })
                .eq('id', payload?.id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default EnrollmentRequestService;
