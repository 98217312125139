import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class DashboardService {
    async getTotalStudents() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PROFILES)
                .select('*', { count: 'exact' })
                .eq('role', 'student')
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getTotalTeachers() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PROFILES)
                .select('*', { count: 'exact' })
                .eq('role', 'teacher');

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getTotalStaff() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PROFILES)
                .select('*', { count: 'exact' })
                .not('role', 'in', `(${['teacher', 'student'].join(',')})`);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getTotalActiveStudents() {
        return withSafety(async () => {
            const { count } = await supabase
                .from(Table.PROFILES)
                .select('*', { count: 'exact', head: true })
                .eq('role', 'student')
                .eq('is_active', true);
            return count;
        });
    }

    async getTotalActiveEnrolledStudents() {
        return withSafety(async () => {
            const { data } = await supabase.from(Table.STUDENTS_COURSES).select('student_id');

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getNewEnrolledStudents({ oldEnrolledStudentIds }) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PROFILES)
                .select('created_at')
                .eq('role', 'student')
                .eq('is_active', true)
                .not('id', 'in', `(${[...oldEnrolledStudentIds].join(',')})`);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getActiveBatches() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES)
                .select(columns('*', 'course:course_id(name, is_disabled)'))
                .gt('end_date', new Date().toISOString());

            return data.length > 0 ? AppResult.withData(toCamelCase(data)) : [];
        });
    }

    async getUpcomingBatches() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES)
                .select(columns('*', 'course:course_id(name, is_disabled)'))
                .gt('start_date', new Date().toISOString());

            return data.length > 0 ? AppResult.withData(toCamelCase(data)) : [];
        });
    }

    async getCompletedBatches() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES)
                .select(columns('*', 'course:course_id(name, is_disabled)'))
                .lt('end_date', new Date().toISOString());

            return data.length > 0 ? AppResult.withData(toCamelCase(data)) : [];
        });
    }

    async getStudentsByBatches({ batchIds }) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BATCHES_STUDENTS)
                .select('*')
                .in('batch_id', batchIds)
                .eq('removed', false);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getStudentsWithAPurchase() {
        const { data } = await supabase
            .from(Table.PURCHASES)
            .select(columns('course:course_id(name)'));

        return AppResult.withData(toCamelCase(data));
    }
    async getStudentCountWithPendingPayments() {
        const { data } = await supabase
            .from(Table.PAYMENT_DUES)
            .select(columns('purchase_id(student_id, course:course_id(name))'));

        return AppResult.withData(toCamelCase(data));
    }
    async getSelectedCourseData() {
        const { data } = await supabase
            .from(Table.PURCHASES)
            .select(columns('*, student:student_id(*), course:course_id(*), payment_dues(*)'));

        return AppResult.withData(toCamelCase(data));
    }
}

export default DashboardService;
