import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class CourseService {
    async createCourse(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.COURSES)
                .insert({
                    name: payload.name,
                    duration: payload.duration,
                    type: payload.type,
                    has_certificate: payload.hasCertificate,
                    has_job_assistance: payload.hasJobAssistance,
                    course_description: payload.courseDescription,
                    short_description: payload.shortDescription,
                    price: payload.price,
                    online: payload.online,
                    offline: payload.offline,
                    emi_months: payload?.emiMonths,
                    downpayment_percentage: payload?.downpaymentPercentage,

                    created_by: authService.getCurrentUser().id,
                    updated_by: authService.getCurrentUser().id
                })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getCourses(excludedIds = []) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.COURSES)
                .select(columns(`*`, `created_by(full_name)`, `updated_by(full_name)`))
                .not('id', 'in', `(${excludedIds.join(',')})`)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getCoursesDropdownData(excludedIds = []) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.COURSES)
                .select(columns(`id`, 'name'))
                .not('id', 'in', `(${excludedIds.join(',')})`)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateCourse(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.COURSES)
                .update({
                    name: payload.name,
                    duration: payload.duration,
                    type: payload.type,
                    has_certificate: payload.hasCertificate,
                    has_job_assistance: payload.hasJobAssistance,
                    course_description: payload.courseDescription,
                    short_description: payload.shortDescription,
                    price: payload.price,
                    is_disabled: payload.isDisabled,
                    offline: payload.offline,
                    online: payload.online,
                    emi_months: payload?.emiMonths,
                    downpayment_percentage: payload?.downpaymentPercentage,
                    image_url: payload.imageUrl
                })
                .eq('id', payload?.id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getCourse(courseId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.COURSES)
                .select('*')
                .eq('id', courseId)
                .single();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getCoursePurchaseData(courseId, studentId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PURCHASES)
                .select('*, payment_dues(*)')
                .eq('course_id', courseId)
                .eq('student_id', studentId)
                .single();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default CourseService;
